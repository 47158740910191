import React, { useRef, useEffect, useState } from 'react';
import  { client, urlFor } from '../../client';
import './PartnersPage.scss';
import { useTranslation } from 'react-i18next';

const PartnersPage = () => {
    const wrapperRef = useRef(null);
    const { t } = useTranslation();
    const [partners, setPartners] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const data = await client.fetch(
                `*[_type == "partnersPage"]{
          partners[]{
            image{
              asset->{
                _id,
                url
              }
            },
            link
          }
        }`
            );
            setPartners(data[0]?.partners || []);
        };

        fetchData();
    }, []);

    useEffect(() => {
        const wrapper = wrapperRef.current;
        let scrollAmount = 0;
        const scrollStep = 1;
        const delay = 20;

        const scrollInterval = setInterval(() => {
            if (wrapper.scrollLeft >= wrapper.scrollWidth - wrapper.clientWidth) {
                scrollAmount = 0;
            } else {
                scrollAmount += scrollStep;
            }
            wrapper.scrollLeft = scrollAmount;
        }, delay);

        return () => clearInterval(scrollInterval);
    }, []);

    return (
        <section id="partners" className="PartnersPage">
            <div className="container">
                <div className="global-title">{t('partners')}</div>
                <div className="global-subtitle">{t('companies')}</div>
                <div className="about-line"></div>
            </div>
            <div className="wrapper" ref={wrapperRef}>
                {partners.map((partner, index) => (
                    partner.link ? (
                        <a key={index} href={partner.link}>
                            <img src={urlFor(partner.image).url()} alt={`partner${index + 1}`} />
                        </a>
                    ) : (
                        <img key={index} src={urlFor(partner.image).url()} alt={`partner${index + 1}`} />
                    )
                ))}
            </div>
        </section>
    );
};

export default PartnersPage;