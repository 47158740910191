import React from 'react';
import MenuView from "./components/menu_bar/MenuView";
import HomePage from "./components/main_page/HomePage";
import AboutPage from "./components/about_page/AboutPage";
import CoursePage from "./components/course_page/CoursePage";
import GlobalPage from "./components/gloabal_page/GlobalPage";
import {Element} from 'react-scroll';
import PartnersPage from "./components/partners_page/PartnersPage";
import Footer from "./components/footer/Footer";
import SocialMedia from "./components/social_media/SocialMedia";
import Map from "./components/map/Map";
import LanguageSwitcher from "./components/language/LanguageSwitcher";
import NewsPage from "./components/news_page/NewsPage";


import './App.scss';


function App() {
    return (
        <div className="App">
            <MenuView/>
            <Element name="home">
                <HomePage/>
            </Element>
            <Element name="about">
                <AboutPage/>
            </Element>
            <Element name="news">
                <NewsPage/>
            </Element>
            <Element name="facts">
                <CoursePage/>
            </Element>
            <Element name="statistics">
                <GlobalPage/>
            </Element>
            <Element name="partners">
                <PartnersPage/>
            </Element>
            <Footer/>
            <SocialMedia/>
            <LanguageSwitcher/>
        </div>
    );
}

export default App;