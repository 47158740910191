// src/components/VideoPlayer.js
import React, { useRef, useState, useEffect } from 'react';
import { fileUrlFor } from '../../client';
import { useTranslation } from 'react-i18next';
import './VideoPlayer.scss';

const VideoPlayer = ({ video }) => {
    const { t, i18n } = useTranslation();
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        const handlePlay = () => {
            document.querySelectorAll('video').forEach((v) => {
                if (v !== videoRef.current) {
                    v.pause();
                }
            });
        };

        videoRef.current.addEventListener('play', handlePlay);

        return () => {
            videoRef.current.removeEventListener('play', handlePlay);
        };
    }, []);

    const togglePlay = () => {
        if (videoRef.current.paused) {
            // Pause other videos before starting this one
            document.querySelectorAll('video').forEach((v) => {
                if (v !== videoRef.current) {
                    v.pause();
                }
            });
            videoRef.current.play();
            setIsPlaying(true);
        } else {
            videoRef.current.pause();
            setIsPlaying(false);
        }
    };

    const handleEnded = () => {
        setIsPlaying(false);
    };

    const videoUrl = fileUrlFor(video.videoFile.asset._ref);

    return (
        <div className="video-player">
            <video
                ref={videoRef}
                controls
                src={videoUrl}
                type="video/mp4"
                onClick={togglePlay}
                onEnded={handleEnded}
            />
            <div className="video-title">{video.title[i18n.language]}</div>
        </div>
    );
};

export default VideoPlayer;
