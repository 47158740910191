import React, {useState, useEffect} from 'react';
import logo from '../../assets/img/logo.svg';
import {Link} from 'react-scroll';
import './MenuView.scss';
import {motion} from "framer-motion";
import {useTranslation} from "react-i18next";

const MenuView = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isActive, setActive] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 30) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleNavbar = () => {
        setActive(!isActive);
    };

    return (
        <navbar>
            <div className={`MenuView ${isScrolled ? 'scrolled' : ''}`}>
                <div className="link-wrapper">
                    <div className="logo">
                        <img src={logo} alt="big-i logo"/>
                        <div className="logo-text">
                            <span>BIG-I</span> STUDY
                        </div>
                    </div>
                    {['home', 'about','news', 'facts', 'statistics', 'partners'].map((item) => (
                        <Link to={item} smooth={true} duration={500} className="page__link" key={item}>
                            {t(item)}
                        </Link>
                    ))}
                    <a href="https://forms.gle/x2UE9LcohCTyJZ2r7" className="page__link registration">
                        {t('registration')}
                    </a>
                </div>
            </div>
            <div className="mobile-navbar">
                <div className={`burger ${isActive ? 'active' : ''}`} onClick={toggleNavbar}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                {isActive && (
                    <motion.div
                        initial={{ x: -300 }}
                        animate={{ x: 0 }}
                        transition={{ duration: 0.35, ease: 'easeOut' }}
                    >
                        <div className="mobile-links">
                            <div className="mobile-wrapper">
                                {['home', 'about','news', 'facts', 'statistics', 'partners'].map((item) => (
                                    <Link
                                        to={item}
                                        smooth={true}
                                        duration={500}
                                        className="page__mobile-link"
                                        key={item}
                                        onClick={toggleNavbar}
                                    >
                                        {t(item)}
                                    </Link>
                                ))}
                                <a href="https://forms.gle/x2UE9LcohCTyJZ2r7" className="registration" onClick={toggleNavbar}>
                                    {t('registration')}
                                </a>
                            </div>
                        </div>
                    </motion.div>
                )}
            </div>
        </navbar>

    );
}

export default MenuView;
