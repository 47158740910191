import './Footer.scss';
import license_file from '../../assets/img/Tasdiqnoma.pdf';
import photo_certificate from '../../assets/img/photo_2024-06-05_05-48-30.jpg';
import affidavit from '../../assets/img/Affidavit.pdf';
import {useTranslation} from "react-i18next";

const Footer = () => {
    const {t} = useTranslation();

    return (
        <footer className="Footer">
            <div className="wrapper">
                <div className="footer-item">
                    <div className="footer-item-title">{t('our_address')}</div>
                    <div className="address footer-item-subtitle">
                        Namangan IT PARK 2nd house, Amir Temur street, Namangan city, Namangan region, Republic of
                        Uzbekistan
                    </div>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d6022.138542257313!2d71.659337!3d41.001857!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDHCsDAwJzA2LjciTiA3McKwMzknMzMuNiJF!5e0!3m2!1sen!2s!4v1717744677150!5m2!1sen!2s"
                        width="600"
                        height="450"
                        style={{border: 0}}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>
                <div className="footer-item">
                    <div className="footer-item-title">{t('documents')}</div>
                    <a className="footer-item-subtitle" href={license_file} target="_blank" rel="noopener noreferrer">
                        {t('license')}
                    </a>
                    <a className="footer-item-subtitle" href={photo_certificate} target="_blank"
                       rel="noopener noreferrer">
                        {t('certificate')}
                    </a>
                    <a className="footer-item-subtitle" href={affidavit} target="_blank" rel="noopener noreferrer">
                        {t('affidavit')}
                    </a>
                </div>
                <div className="footer-item">
                    <div className="footer-item-title">E-mail</div>
                    <a href="mailto:studybigi@gmail.com" className="email footer-item-subtitle">
                        studybigi@gmail.com
                    </a>
                </div>
                <div className="footer-item">
                    <div className="footer-item-title">{t('contact_numbers')}</div>
                    <a className="footer-item-subtitle" href="tel:+998957270017">
                        +998 95 727 00 17
                    </a>
                    <a className="footer-item-subtitle" href="tel:+998993200017">
                        +998 99 320 00 17
                    </a>
                </div>
            </div>
            <div className="copy-right footer-item-subtitle">
                Copyright © 2024 <a className="footer-item-subtitle" href="https://etamin.agency">ETAMIN</a>
            </div>
        </footer>
    );
};

export default Footer;
