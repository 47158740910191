import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {client} from '../../client';
import VideoPlayer from '../player/VideoPlayer';

import './NewsPage.scss';

const NewsPage = () => {
    const {t} = useTranslation();
    const [videoData, setVideoData] = useState(null);

    useEffect(() => {
        client.fetch('*[_type == "news"]').then((data) => {
            if (data&&data?.length!==0){
                setVideoData(data);
            }

        }).catch(console.error);
    }, []);
        console.log(videoData)
    return (
        videoData &&
        <section className="NewsPage">
            <div className="container">
                <div className="news-title">{t('news')}</div>
                <div className="news-subtitle">{t('news-desc')}</div>
                <div className="about-line"></div>
                <div className="video-wrapper">

                        <>
                            {videoData.map((video, index) => (
                                <VideoPlayer key={index} video={video} />
                            ))}
                        </>
                </div>
            </div>
        </section>
    );
};

export default NewsPage;
