import './GlobalPage.scss'
import {useTranslation} from "react-i18next";

const GlobalPage = () => {
    const {t} = useTranslation();

    return (
        <section id="statistics" className="GlobalPage">
            <div className="container">
                <div className="global-title">
                    {t('global-title')}
                </div>
                <div className="global-subtitle">
                    {t('global-subtitle')}
                </div>
                <div className="about-line"></div>

                <div className="wrapper">
                    <div className="line-item">
                        <div className="global-item-title">{t('statistics')} #1</div>
                        <div className="global-item-subtitle">{t('statistics_1')}
                        </div>
                        <div className="line-progress-item-1">
                        </div>
                    </div>
                    <div className="line-item">
                        <div className="global-item-title">{t('statistics')} #2</div>
                        <div className="global-item-subtitle">
                            {t('statistics_2')}

                        </div>
                        <div className="line-progress-item-2">
                            <div className="line-progress-item-blue">
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrapper">
                    <div className="fat-item item-1">
                        <div className="global-item-title">{t('statistics')} #3</div>
                        <div className="global-item-subtitle">
                            {t('statistics_3')}
                        </div>
                        <div className="fat-item-1 ">
                            <div className="text">
                                Applications 88%
                            </div>
                            <div className="line">

                            </div>
                        </div>
                    </div>
                    <div className="fat-item item-2">
                        <div className="global-item-title">{t('statistics')} #4</div>
                        <div className="global-item-subtitle">
                            {t('statistics_4')}
                        </div>
                        <div className="fat-item-2">
                            <div className="text">Height 100%</div>
                        </div>
                    </div>
                    <div className="fat-item  item-1">
                        <div className="global-item-title">{t('statistics')} #5</div>
                        <div className="global-item-subtitle">
                            {t('statistics_5')}
                        </div>
                        <div className="fat-item-3 ">
                            <div className="text">
                                passes about 314,840 km.
                            </div>
                            <div className="line">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default GlobalPage;