import call_icon from '../../assets/img/call-icon.png'
import message_icon from '../../assets/img/message-icon.svg';
import { useTranslation } from 'react-i18next';

import './HomePage.scss'

const HomePage = () => {
    const {t} = useTranslation();

    return (
        <header id="home" className="HomePage">
            <div className="container">
                <div className="wrapper">
                    <div className="wrapper-item">
                        <h1 className="home-title">
                            {t('international_academy')}
                        </h1>
                        <div className="home-subtitle">
                            {t('home_subtitle')}
                            <br/>
                            {t('12_steps')}
                        </div>
                        <div className="home-buttons">
                            <a href="tel:+998957270017" className="home-call"><img src={call_icon} alt="call icon"/>{t('home_call')}</a>
                            <a href="https://t.me/bigi_study" className="home-call message"><img src={message_icon}
                                                                                                 alt="call icon"/>{t('home_message')}</a>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}


export default HomePage;