import React, { useState, useEffect } from 'react';
import {client} from '../../client';
import shipping_img from '../../assets/img/shipping-about.png';
import { useTranslation } from 'react-i18next';
import './AboutPage.scss';
import spinner from "../../assets/img/spinner.svg";

const AboutPage = () => {
    const { i18n, t } = useTranslation();
    const [aboutData, setAboutData] = useState(null);

    useEffect(() => {
        client
            .fetch(
                `*[_type == "aboutPage"][0]{
          firstSide,
          secondSide
        }`
            )
            .then((data) => setAboutData(data))
            .catch(console.error);
    }, []);

    if (!aboutData) return <div className="spinner"><img src={spinner} alt="spinner"/></div>;

    return (
        <section id="about" className="AboutPage">
            <div className="container">
                <div className="about-title">
                    {t('about_us')}
                </div>
                <div className="about-subtitle">
                    {t('about_us_subtitle')}
                </div>
                <div className="about-line"></div>
                <div className="about-wrapper">
                    <div className="about-item">
                        <div className="about-item-title">
                            {t('journey_text')} <br />
                            <span>{t('doctor')}</span>
                            <div className="about-line-left "></div>
                            <div className="about-item-text">
                                {aboutData&&aboutData?.firstSide[i18n.language]}
                            </div>
                        </div>
                    </div>
                    <div className="about-item">
                        <div className="about-item-text">
                            {aboutData&&aboutData?.secondSide[i18n.language]}
                        </div>
                    </div>
                    <div className="about-item">
                        <div className="about-image-block">
                            <img src={shipping_img} alt="shipping goods picture" className="about-image" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutPage;
