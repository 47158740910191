import React, {useState, useEffect} from 'react';
import {client, urlFor} from '../../client';
import {useTranslation} from 'react-i18next';
import './CoursePage.scss';
import spinner from '../../assets/img/spinner.svg'
import circle_right_image from '../../assets/img/Circled_Right.png';


const CoursePage = () => {
    const {i18n} = useTranslation();
    const [courseData, setCourseData] = useState(null);
    const {t} = useTranslation();

    useEffect(() => {
        client
            .fetch(
                `*[_type == "aboutUs"]{
          title,
          subtitle,
          image{
            asset->{
              _id,
              url
            }
          }
        }`
            )
            .then((data) => {
                setCourseData(data)
            })
            .catch(console.error);
    }, []);
    if (!courseData) return <div className="spinner"><img src={spinner} alt="spinner"/></div>;
    return (
        <section id="facts" className="CoursePage">
            <div className="container">
                <div className="course-title">
                    {t('course-title')}
                </div>
                <div className="course-subtitle">
                    {t('course-subtitle')}
                </div>
                <div className="about-line"></div>
                <div className="course-wrapper">
                    {courseData?.map((item, index) => (
                        <div key={index} className="course-item">
                            <div className="course-item-image">
                                <img src={urlFor(item.image).url()} alt="course item image"/>
                            </div>
                            <div className="course-item-title">
                                {item.title[i18n.language]}
                            </div>
                            <div className="course-item-subtitle">
                                {item.subtitle[i18n.language]}
                            </div>
                            <div className="course-item-next-image">
                                <img src={circle_right_image} alt="circle right image"/>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default CoursePage;
