import React, { useEffect, useState } from 'react';
import  {client, urlFor } from '../../client';
import './SocialMedia.scss';

const SocialMedia = () => {
    const [isNearBottom, setIsNearBottom] = useState(false);
    const [socialMediaLinks, setSocialMediaLinks] = useState([]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;

            if (scrollTop + windowHeight >= documentHeight - 200) {
                setIsNearBottom(true);
            } else {
                setIsNearBottom(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        client
            .fetch(
                `*[_type == "socialMedia"]{
          platforms[]{
            name,
            link,
            icon{
              asset->{
                _id,
                url
              }
            }
          }
        }`
            )
            .then((data) => setSocialMediaLinks(data[0]?.platforms || []))
            .catch(console.error);
    }, []);

    return (
        <div className={`SocialMedia ${isNearBottom ? 'row' : ''}`}>
            {socialMediaLinks?.map((platform, index) => (
                <a key={index} href={platform?.link}>
                    <img src={urlFor(platform?.icon).url()} alt={platform?.name} className="social_links" />
                </a>
            ))}
        </div>
    );
};

export default SocialMedia;
